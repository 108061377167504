


































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    OEventEditInfo: () =>
      import("@/components/organisms/event/eventData/o-event-edit-info.vue"),
    OEventLocation: () =>
      import("@/components/organisms/event/eventData/o-event-location.vue"),
    OEventInvoice: () =>
      import("@/components/organisms/event/eventData/o-event-invoice.vue"),
    OEventEmail: () =>
      import("@/components/organisms/event/eventData/o-event-email.vue"),
    OEventDegree: () =>
      import("@/components/organisms/event/o-event-degree.vue"),
  },

  setup(_, { root }) {
    const model = reactive<{
      data: any;
    }>({
      data: {
        name: "",
        type: "",
        prefix: "",
        startDate: "",
        endDate: "",
        amount: "",
        languageVersion: "",
        invoice: "",
        company: "",
        summary: "",
        posterSession: "",
        survey: "",
        print: "",
        location: "",
      },
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      items: [],
      dialog: false,
    });

    //  Start fetch single event data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          model.data.name = event.name;
          model.data.type = event.type;
          model.data.prefix = event.prefix;
          model.data.amount = event.amount || undefined;
          model.data.languageVersion = event.languageVersion || "";
          model.data.startDate = event.startDate || "";
          model.data.endDate = event.endDate || "";
          model.data.invoice = event.invoice || "";
          model.data.company = event.company || "";
          model.data.summary = event.summary || "";
          model.data.posterSession = event.posterSession || "";
          model.data.survey = event.survey || "";
          model.data.print = event.print || "";
          model.data.location = event.location || "";
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single event data

    return { model };
  },
});
